@font-face {
    font-family: 'Termina-Test';
    src: url('../../shared/fonts/termina-test/TerminaTest-Regular.woff') format('woff');
}

body,
span,
div,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
button,
ul,
li,
input,
select {
    font-family: 'Termina-Test', sans-serif !important;
}

.css-nvf14r-ToastContainer {
    z-index: 2000 !important;
}

.flex {
    display: flex;
}

.block {
    display: block;
}

@media screen and (max-width: 576px) {

    h2.fc-toolbar-title {
        font-size: 13px !important;
    }

    div.fc-header-toolbar.fc-toolbar.fc-toolbar-ltr {
        padding: 0px !important;
    }

    .mobile-hidden {
        display: none !important;
    }

    .mobile-visible {
        display: block !important;
    }

    .mobile-flex-column {
        flex-direction: column;
    }

    .mobile-flex-row {
        flex-direction: row;
    }

    .mobile-justify-center {
        justify-content: center;
    }

    .mobile-justify-start {
        justify-content: flex-start;
    }

    .mobile-justify-end {
        justify-content: flex-end;
    }

    .mobile-justify-between {
        justify-content: space-between;
    }

    .mobile-align-center {
        align-items: center;
    }

    .mobile-align-start {
        align-items: flex-start;
    }

    .mobile-align-end {
        align-items: flex-end;
    }
}

@media screen and (min-width:577px) {
    .tablet-hidden {
        display: none;
    }
}

@media screen and (min-width: 992px) {
    .desktop-flex-column {
        display: flex;
        flex-direction: column;
    }

    .desktop-flex-row {
        display: flex;
        flex-direction: row;
    }

    .desktop-justify-center {
        justify-content: center;
    }

    .desktop-justify-start {
        justify-content: flex-start;
    }

    .desktop-justify-end {
        justify-content: flex-end;
    }

    .desktop-justify-between {
        justify-content: space-between;
    }

    .desktop-align-center {
        align-items: center;
    }

    .desktop-align-start {
        align-items: flex-start;
    }

    .desktop-align-end {
        align-items: flex-end;
    }
}

.main-content {
    background-color: #f6f9f5 !important;
}

/* 
body {
    background-color: #f6f9f5 !important;
} */

/* CustomToast.css */
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0;
        transform: translateY(-20px);
    }
}

.custom-toast {
    padding: 16px;
    border-radius: 8px;
    color: white;
    margin-bottom: 10px;
    font-weight: 600;
    display: flex;
    align-items: center;
    animation-duration: 1s;
    font-size: 14px;
}

.custom-toast-enter {
    animation: fadeIn 0.3s forwards;
}

.custom-toast-exit {
    animation: fadeOut 0.3s forwards;
}

.custom-toast-info {
    background-color: #5bc0de;
}

.custom-toast-success {
    background-color: #5cb85c;
}

.custom-toast-error {
    background-color: #d9534f;
}

.custom-toast-warning {
    background-color: #f0ad4e;
}